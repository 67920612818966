import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-left: 10px;
  select {
    max-width: 75px;
  }
`;
