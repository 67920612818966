import { useMemo, useState } from "react";
import { api } from "../../services/api";

export function useFidelity({ shoppingButtonSelected, businessDatas }) {
	const [pointsFidelity, setPointsFidelity] = useState({});
	const [rewardsFidelity, setRewardsFidelity] = useState([]);
	const [isVisibleModalFidelity, setIsVisibleModalFidelity] = useState(false);
	const [verifyFidelity, setVerifyFidelity] = useState(true);

	const [fidelity, setFidelity] = useState({
		type: "C",
		totalResgatado: 0,
		totalCashbackResgatado: 0,
		isCashback: false,
	});

	const isValidLoyalty = useMemo(() => {
		const {
			usaFidelidadeLink,
			usaFidelidade,
			usaFidelidadeDelivery,
			usaFidelidadeBalcao,
		} = businessDatas;

		if (usaFidelidadeLink && usaFidelidade) {
			switch (shoppingButtonSelected) {
				case "delivery":
					return usaFidelidadeDelivery;
				case "takeaway":
					return usaFidelidadeBalcao;
				default:
					return false;
			}
		}

		return false;
	}, [businessDatas, shoppingButtonSelected]);

	async function onGetFilitiesUser() {
		try {
			const token = localStorage.getItem("@Inoveclube:token");
			const idBusiness = businessDatas.idBusiness;

			const response = await api.get(`/getCheckLFidelityUserScore/${idBusiness}`, {
				headers: {
					"Content-Type": "application/json",
					token: token,
				},
			});

			const { points, rewards } = response.data;

			return {
				points,
				rewards,
			};
		} catch (error) {
			return null;
		}
	}

	function onCloseModalFidelity() {
		setVerifyFidelity(false);
		setIsVisibleModalFidelity(false);
	}

	async function onOpenModalFidelity({ callback = () => {} }) {
		const fidelityResponse = await onGetFilitiesUser();

		if (fidelityResponse) {
			const { points, rewards } = fidelityResponse;

			if (points.totalCashback > 0 || points.totalPontos > 0) {
				setPointsFidelity(points);
				setRewardsFidelity(rewards);
				setIsVisibleModalFidelity(true);
				return;
			}
		}
		callback();
	}

	function onAddCashback({ totalCashback, totalResgatado }) {
		setFidelity({
			isCashback: true,
			totalCashbackResgatado: totalCashback,
			totalResgatado: totalResgatado,
			type: "R",
		});
		onCloseModalFidelity();
	}

	function onAddPoints({ totalCashback, totalResgatado }) {
		setFidelity({
			isCashback: false,
			totalCashbackResgatado: totalCashback,
			totalResgatado: totalResgatado,
			type: "R",
		});
		onCloseModalFidelity();
	}

	return {
		isValidLoyalty,
		isVisibleModalFidelity,
		pointsFidelity,
		rewardsFidelity,
		verifyFidelity,
		fidelity,
		onCloseModalFidelity,
		onOpenModalFidelity,
		onAddCashback,
		onAddPoints,
	};
}
