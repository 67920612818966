import { CardProduct } from "../styles";

export function Card({ selected, disabled, onAdd, data, totalPoints }) {
	const difference = data.pontos - totalPoints;

	return (
		<CardProduct selected={selected} onClick={() => onAdd(data)} disabled={disabled}>
			<div className="background" />

			{data.linkImagem && <img src={data.linkImagem} alt={`Prêmio ${data.produto}`} />}
			<div>
				<p>{data.produto}</p>
				<p>{data.pontos} pontos</p>

				{difference > 0 && <p className="difference">Faltam {difference} pontos!</p>}
			</div>
		</CardProduct>
	);
}
