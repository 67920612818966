import { SmallModal } from "../../../../components/smallModal";
import { useTranslator } from "../../../../context/TranslatorContext";
import { useOrderManagerContext } from "../../context";
import { OrderCard } from "../orderCard";
import { Container } from "./style";

export function SchedulingModal() {
	const { getText } = useTranslator();
	const { showSchedulingModal, setShowSchedulingModal } = useOrderManagerContext();
	const { schedulesOrders, schedulesOrdersIsLoading } = useOrderManagerContext();

	if (!showSchedulingModal) return null;
	return (
		<SmallModal
			title={getText("order-manager.scheduling-modal.title")}
			toClose={() => setShowSchedulingModal(false)}
			loading={schedulesOrdersIsLoading}
		>
			<Container>
				{!schedulesOrdersIsLoading && schedulesOrders?.length === 0 && (
					<h3>{getText("order-manager.scheduling-modal.empty")}</h3>
				)}
				{schedulesOrders?.map((item) => {
					return <OrderCard isSchedule key={item.idOrcamento} item={item} />;
				})}
			</Container>
		</SmallModal>
	);
}
