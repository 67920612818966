import { useEffect, useState } from "react";
import { useOrderManagerContext } from "../../context";
import {
	Backdrop,
	Container,
	DialogBody,
	DialogFooter,
	DialogHeader,
	Modal,
	Title,
} from "./styles";
import { XCloseButton } from "../../../../components/ui/xCloseButton";
import { Button } from "../../../../components/Button";
import { Select } from "../../../../components/Forms/select";
import { getMotoboy } from "../../requests/getMotoboy";
import { updateMotoboy } from "../../requests/updateMotoboy";
import { useTranslator } from "../../../../context/TranslatorContext";

export function ChoseMotoboy() {
	const { getText } = useTranslator();
	const { choseMotoboy, setChoseMotoboy } = useOrderManagerContext();
	const { motoboyDialogInfo, setMotoboyDialogInfo } = useOrderManagerContext();

	const [motoboy, setMotoboy] = useState("");
	const [motoboyList, setMotoboyList] = useState([]);

	function handleClose() {
		setChoseMotoboy(false);
		setMotoboyDialogInfo(null);
		setMotoboy("");
	}

	function handleChoseMotoboy() {
		updateMotoboy({
			idOrcamento: motoboyDialogInfo.idOrcamento,
			cdMotoboy: motoboy,
		}).then(() => {
			setChoseMotoboy(false);
			setMotoboyDialogInfo(null);
			setMotoboy("");
		});
	}

	function handleSelectMotoboy(e) {
		setMotoboy(e.target.value);
	}

	useEffect(() => {
		if (!motoboyDialogInfo) return;
		getMotoboy({ company: motoboyDialogInfo.company }).then((data) => {
			setMotoboyList(data);
		});
	}, [motoboyDialogInfo]);

	return !choseMotoboy || motoboyList.length === 0 ? (
		<></>
	) : (
		<Container>
			<Modal>
				<DialogHeader>
					<Title>{getText("order-manager.chose-motoboy.title")}</Title>
					<XCloseButton toClose={handleClose} />
				</DialogHeader>
				<DialogBody>
					<Select
						name="motoboy"
						options={motoboyList}
						onChange={handleSelectMotoboy}
						value={motoboy}
						label="Motoboy"
						description="nmPessoa"
						optionValue="cdPessoa"
						placeholder={getText("order-manager.chose-motoboy.select-placeholder")}
					/>
				</DialogBody>
				<DialogFooter>
					<div className="flex">
						<Button onClick={handleClose} background="var(--red-500)">
							{getText("order-manager.chose-motoboy.cancel")}
						</Button>
						<Button onClick={handleChoseMotoboy} background="var(--green-500)">
							{getText("order-manager.chose-motoboy.confirm")}
						</Button>
					</div>
				</DialogFooter>
			</Modal>
			<Backdrop />
		</Container>
	);
}
