import { useTranslator } from "../../../../context/TranslatorContext";
import { useOrderManagerContext } from "../../context";
import { OrderCard } from "../orderCard";
import { OrdersLoading } from "./loader";
import { CardsContainer, CardsWrapper } from "./style";

export function Orders() {
	const { getText } = useTranslator();
	const { orders, ordersIsLoading, orderIsPrinting } = useOrderManagerContext();

	const allPending = orders.filter(
		(item) =>
			item.dsStatus === "A" || (item.dsStatus === "W" && item.dtPgtoOnline !== null),
	);
	const allPreparing = orders.filter((item) => item.dsStatus === "P");
	const allDelivering = orders.filter((item) => item.dsStatus === "E");

	if (ordersIsLoading) return <OrdersLoading />;

	if (orderIsPrinting) return null;
	return (
		<CardsContainer>
			<CardsWrapper>
				<header className="wrapper-header">
					<h2>{getText("order-manager.order-card.pendent")}</h2>
					<span>{`( ${allPending.length} )`}</span>
				</header>
				<div className="wrapper-body">
					{allPending.map((item) => {
						return <OrderCard key={item.idOrcamento} item={item} />;
					})}
				</div>
			</CardsWrapper>
			<CardsWrapper>
				<header className="wrapper-header">
					<h2>{getText("order-manager.order-card.preparing")}</h2>
					<span>{`( ${allPreparing.length} )`}</span>
				</header>
				<div className="wrapper-body">
					{allPreparing.map((item) => {
						return <OrderCard key={item.idOrcamento} item={item} />;
					})}
				</div>
			</CardsWrapper>
			<CardsWrapper>
				<header className="wrapper-header">
					<h2>{getText("order-manager.order-card.delivering")}</h2>
					<span>{`( ${allDelivering.length} )`}</span>
				</header>
				<div className="wrapper-body">
					{allDelivering.map((item) => {
						return <OrderCard key={item.idOrcamento} item={item} />;
					})}
				</div>
			</CardsWrapper>
		</CardsContainer>
	);
}
