import { useState } from "react";

import {
	Container,
	Content,
	Header,
	Main,
	ButtonsRedemptionContent,
	ButtonRedemption,
	ButtonCancel,
	PointsContainer,
	Point,
} from "./styles";
import { FaTimes } from "react-icons/fa";

import { formatMoney } from "../../Utils/formatMoney";

import { ProductsList } from "./productsList";

export function FidelityModal({
	isOpen,
	onClose,
	points,
	rewards,
	vlAbateCashback = 1,
	vlAbatePontos = 1,
	valueOrder,
	onAddCashback,
	onAddPoints,
	onAddItemByFidelity,
}) {
	const [showProducts, setShowProducts] = useState(false);

	if (!isOpen) {
		return null;
	}

	function onClickButtonCashback() {
		const totalCashback =
			points?.totalCashback >= valueOrder ? valueOrder : points?.totalCashback;

		const totalResgatado =
			(points?.totalCashback >= valueOrder ? valueOrder : points?.totalCashback) *
			vlAbatePontos;

		onAddCashback({
			totalCashback,
			totalResgatado,
		});
	}

	return (
		<Container>
			<Content isOpen={isOpen}>
				<Header>
					<button onClick={showProducts ? () => setShowProducts(false) : onClose}>
						<FaTimes />
					</button>

					<h1>{showProducts ? "Selecione o prêmio" : "Resgate de Prêmios"}</h1>
				</Header>

				{showProducts ? (
					<Main>
						<ProductsList
							rewards={rewards}
							onAddItemByFidelity={onAddItemByFidelity}
							onAddPoints={onAddPoints}
							totalPoints={points.totalPontos}
							totalCashback={points.totalCashback}
							total={valueOrder}
							vlAbateCashback={vlAbateCashback}
						/>
					</Main>
				) : (
					<Main>
						<PointsContainer>
							<Point>
								<strong>Saldo em cashback</strong>
								<span>{formatMoney(points.totalCashback)}</span>
							</Point>

							<Point>
								<strong>Saldo em pontos</strong>
								<span>{points.totalPontos}</span>
							</Point>
						</PointsContainer>

						<ButtonsRedemptionContent>
							<ButtonRedemption onClick={onClickButtonCashback}>
								Resgate em Cashback
							</ButtonRedemption>

							<ButtonRedemption onClick={() => setShowProducts(true)}>
								Resgate em Produtos
							</ButtonRedemption>
						</ButtonsRedemptionContent>

						<ButtonCancel onClick={onClose}>Não Resgatar</ButtonCancel>
					</Main>
				)}
			</Content>
		</Container>
	);
}
