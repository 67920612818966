import { useTranslator } from "../../../../context/TranslatorContext";
import { CardsContainer, CardsWrapper, Container } from "./style";

export function OrdersLoading() {
	const { getText } = useTranslator();

	return (
		<Container>
			<CardsContainer>
				<CardsWrapper>
					<header className="wrapper-header">
						<h2>{getText("order-manager.order-card.pendent")}</h2>
					</header>
					<div className="wrapper-body">
						<div className="skeleton-loader"></div>
						<div className="skeleton-loader"></div>
					</div>
				</CardsWrapper>
				<CardsWrapper>
					<header className="wrapper-header">
						<h2>{getText("order-manager.order-card.preparing")}</h2>
					</header>
					<div className="wrapper-body">
						<div className="skeleton-loader"></div>
						<div className="skeleton-loader"></div>
					</div>
				</CardsWrapper>
				<CardsWrapper>
					<header className="wrapper-header">
						<h2>{getText("order-manager.order-card.delivering")}</h2>
					</header>
					<div className="wrapper-body">
						<div className="skeleton-loader"></div>
						<div className="skeleton-loader"></div>
					</div>
				</CardsWrapper>
			</CardsContainer>
		</Container>
	);
}
