import styled, { keyframes, css } from "styled-components";

const animationModal = keyframes`
    from{
        transform: translateY(1000%);
    }
    to{
        transform: translateY(0%);
    }
`;

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9901;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
	width: 95%;
	max-width: 600px;
	background-color: #fff;
	border-radius: 8px;
	padding: 1rem;
	max-height: 90%;
	transform: translateY(1000%);

	animation: ${animationModal} 0.3s ease-in-out forwards;

	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	width: 100%;
	color: #393939;
	position: relative;

	button {
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		color: #393939;
		font-size: 1.1rem;
		position: absolute;
		right: 0;
		top: 0;
	}

	h1 {
		text-align: center;
		font-size: 1.3rem;
	}
`;

export const Main = styled.div`
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const PointsContainer = styled.div`
	width: 100%;
	display: flex;
	margin: 1rem 0;
	align-items: center;
	justify-content: space-around;
`;

export const Point = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
`;

export const ButtonsRedemptionContent = styled.div`
	width: 100%;
	display: flex;
	align-self: center;
	gap: 1rem;
	margin: 1rem;

	@media (max-width: 400px) {
		flex-direction: column;
	}
`;

export const ButtonRedemption = styled.button`
	flex: 1;
	border: none;
	padding: 1rem;
	background: var(--colorButton);
	color: var(--colorButtonBottom);
	border-radius: 0.3rem;
	opacity: 0.7;
	font-weight: bold;
	font-size: 1rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	svg {
		font-size: 1.8rem;
	}
`;

export const ButtonCancel = styled.button`
	background-color: var(--red-500);
	border: none;
	padding: 1rem;
	font-size: 1rem;
	color: #fff;
	border-radius: 0.3rem;
	max-width: 400px;
	margin: 0 auto;
`;

export const ProductContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;

	.total {
		display: flex;
		flex-direction: column;
		gap: 5px;
		text-align: center;
	}
`;

export const Products = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	flex-wrap: wrap;
	padding: 1rem 0;
`;

export const CardProduct = styled.button`
	display: flex;
	align-items: center;
	gap: 1rem;
	position: relative;

	background: none;
	border: 1px solid var(--colorButton);
	border-radius: 4px;
	padding: 0.3rem;
	min-width: 200px;

	img {
		width: 50px;
		height: 50px;
		z-index: 1;
	}

	p {
		z-index: 1;
	}

	.difference {
		color: var(--red-500);
		font-weight: bold;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		z-index: -1;
		opacity: 0.2;
	}

	${(props) =>
		props.selected &&
		css`
			font-weight: bold;

			.background {
				background-color: var(--colorButton);
				z-index: 0;
			}
		`}

	@media (max-width: 500px) {
		width: 100%;
	}
`;
