import { RadioWithLabel } from "../../../../components/radioWithLabel";
import { useOrderManagerContext } from "../../context";
import { Container, Modal, Title, Button } from "./style";
import { useTranslator } from "../../../../context/TranslatorContext";

export function ChangeLanguage() {
	const { choseLanguage, currentLanguage, getText } = useTranslator();
	const { showChangeLanguage, setShowChangeLanguage } = useOrderManagerContext();

	return showChangeLanguage ? (
		<Container>
			<Modal className="scroll">
				<Title>{getText("order-manager.chose-language.modal-title")}</Title>

				<RadioWithLabel
					label={getText("order-manager.chose-language.pt-br")}
					checked={currentLanguage === "pt-BR"}
					onChange={() => {
						choseLanguage("pt-BR");
					}}
				/>
				<RadioWithLabel
					label={getText("order-manager.chose-language.es-es")}
					checked={currentLanguage === "es-ES"}
					onChange={() => {
						choseLanguage("es-ES");
					}}
				/>

				<Button
					className="large"
					onClick={() => {
						setShowChangeLanguage(false);
					}}
				>
					{getText("order-manager.chose-language.close-button")}
				</Button>
			</Modal>
		</Container>
	) : null;
}
