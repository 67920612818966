import { FaArrowLeft, FaQrcode, FaCogs } from "react-icons/fa";
// import { FaMapMarkedAlt } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import { AiFillSound } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { PiStorefrontBold } from "react-icons/pi";
import { FaCashRegister, FaLanguage } from "react-icons/fa6";
import { Container } from "./style";
import { useOrderManagerContext } from "../../context";
import { useTranslator } from "../../../../context/TranslatorContext";

export function TopBar() {
	// Hooks
	const history = useHistory();
	const { getText } = useTranslator();
	const { ordersIsLoading, orders, notify, handleGetOrders } = useOrderManagerContext();
	const { scheduledOrdersAmount, setShowOpenCashRegister } = useOrderManagerContext();
	const { setShowCloseCashRegister, setShowLinksStores } = useOrderManagerContext();
	const { setShowSchedulingModal, orderIsPrinting } = useOrderManagerContext();
	const { setShowManageStoresModal } = useOrderManagerContext();
	const { setShowChangeLanguage } = useOrderManagerContext();

	// Variables
	const notDelivered =
		orders.length === 0
			? 0
			: orders.filter(
					(order) =>
						order.dsStatus === "A" || order.dsStatus === "P" || order.dsStatus === "E",
				).length;
	const concluded =
		orders.length === 0 ? 0 : orders.filter((order) => order.dsStatus === "F").length;

	// Return
	if (orderIsPrinting) return null;
	return (
		<Container>
			<div className="wrapper">
				<div className="logo-wrapper">
					<FaArrowLeft
						className="icon"
						size={26}
						onClick={() => {
							history.push("/adm");
						}}
					/>
					<h1>{getText("order-manager.header-title")}</h1>
				</div>
				<div className="info-wrapper">
					<div className="card-container">
						<span>{ordersIsLoading ? "..." : notDelivered}</span>
						<span>{getText("order-manager.top-bar.not-delivered")}</span>
					</div>
					<div className="card-container">
						<span>{ordersIsLoading ? "..." : concluded}</span>
						<span>{getText("order-manager.top-bar.concluded")}</span>
					</div>
					<div
						className="card-container"
						onClick={() => setShowSchedulingModal(true)}
						onKeyPress={() => setShowSchedulingModal(true)}
					>
						<span>{ordersIsLoading ? "..." : scheduledOrdersAmount}</span>
						<span>{getText("order-manager.top-bar.scheduled-orders")}</span>
					</div>
					<div
						className="card-container"
						onClick={handleGetOrders}
						onKeyPress={handleGetOrders}
					>
						<TbReload />
						<span>{getText("order-manager.top-bar.update")}</span>
					</div>
					<div className="card-container" onClick={notify} onKeyPress={notify}>
						<AiFillSound />
						<span>{getText("order-manager.top-bar.test-sound")}</span>
					</div>
					<div
						className="card-container"
						onClick={() => setShowManageStoresModal(true)}
						onKeyPress={() => setShowManageStoresModal(true)}
					>
						<PiStorefrontBold />
						<span>{getText("order-manager.top-bar.open-stores")}</span>
					</div>
					<div
						className="card-container"
						onClick={() => setShowOpenCashRegister(true)}
						onKeyPress={() => setShowOpenCashRegister(true)}
					>
						<FaCogs className="icon" />
						<span>{getText("order-manager.top-bar.manage-stores")}</span>
					</div>
					<div
						className="card-container"
						onClick={() => setShowCloseCashRegister(true)}
						onKeyPress={() => setShowCloseCashRegister(true)}
					>
						<FaCashRegister className="icon" />
						<span>{getText("order-manager.top-bar.close-cash-register")}</span>
					</div>
					<div
						className="card-container"
						onClick={() => setShowLinksStores(true)}
						onKeyPress={() => setShowLinksStores(true)}
					>
						<FaQrcode className="icon" />
						<span>{getText("order-manager.top-bar.links")}</span>
					</div>
					{/* <div
						className="card-container"
						onClick={() => setShowCalculateRoutes(true)}
						onKeyPress={() => setShowCalculateRoutes(true)}
					>
						<FaMapMarkedAlt className="icon" />
						<span>{getText("order-manager.top-bar.calculate-route")}</span>
					</div> */}
					<div
						className="card-container"
						onClick={() => setShowChangeLanguage(true)}
						onKeyPress={() => setShowChangeLanguage(true)}
					>
						<FaLanguage className="icon" />
						<span>{getText("order-manager.top-bar.change-language")}</span>
					</div>
				</div>
			</div>
		</Container>
	);
}
