import Toggle from "react-toggle";
import { useOrderManagerContext } from "../../context";
import { Button, Container, Modal, Title, List, ListItem } from "./style";
import { useTranslator } from "../../../../context/TranslatorContext";

export function ManageStores() {
	const { getText } = useTranslator();
	const { showManageStoresModal, setShowManageStoresModal } = useOrderManagerContext();
	const { storeIsOpening, setShowOpenCashRegister } = useOrderManagerContext();
	const { handleGetOrders, handleOpenAllStores, companies } = useOrderManagerContext();
	const { handleOpenStore } = useOrderManagerContext();

	return showManageStoresModal ? (
		<Container>
			<Modal className="scroll">
				<Title>{getText("order-manager.manage-stores.store-management")}</Title>
				<>
					<div className="space-x">
						<Button
							className="right"
							disabled={storeIsOpening}
							onClick={() => {
								setShowOpenCashRegister(true);
								setShowManageStoresModal(false);
							}}
						>
							{getText("order-manager.manage-stores.manage-cash-registers")}
						</Button>
						<Button
							className="right"
							disabled={
								!companies.some((company) => !company.lojaAberta) || storeIsOpening
							}
							onClick={handleOpenAllStores}
						>
							{getText("order-manager.manage-stores.open-all-stores")}
						</Button>
					</div>
					<List>
						{companies.map((company) => (
							<ListItem
								key={company.cdEmpresa}
								className={`${company.cashRegisterOpen ? "" : "opacity-50"}`}
							>
								<div className="wrapper">
									<div>
										<p className="bold">{company.dsEmpresa}</p>
										<p>CNPJ: {company.cnpj}</p>
									</div>
								</div>
								<Toggle
									checked={company.lojaAberta}
									onChange={() => handleOpenStore(company.cdEmpresa, !company.lojaAberta)}
									disabled={!company.cashRegisterOpen || storeIsOpening}
								/>
							</ListItem>
						))}
					</List>
					<Button
						className="large"
						disabled={storeIsOpening}
						onClick={() => {
							handleGetOrders();
							setShowManageStoresModal(false);
						}}
					>
						{getText("order-manager.manage-stores.close-button")}
					</Button>
				</>
			</Modal>
		</Container>
	) : null;
}
