import { useState, useMemo } from "react";
import { ProductContainer, Products, ButtonRedemption } from "../styles";

import { Card } from "./Card";

export function ProductsList({
	rewards = [],
	onAddItemByFidelity,
	onAddPoints,
	totalPoints,
	totalCashback,
	vlAbateCashback,
}) {
	const [productsSelected, setProductsSelected] = useState([]);

	const totalPointsSelected = useMemo(() => {
		if (productsSelected.length === 0) return 0;

		return productsSelected
			.map((item) => item.pontos)
			.reduce((value, next) => value + next, 0);
	}, [productsSelected]);

	function isSelected(data) {
		return productsSelected.some((item) => item.cdProduto === data.cdProduto);
	}

	function onAddProductsSelected(data) {
		const hasProduct = isSelected(data);

		if (hasProduct) {
			const arr = productsSelected.filter((item) => item.cdProduto !== data.cdProduto);
			setProductsSelected(arr);
			return;
		}

		setProductsSelected((products) => [...products, data]);
	}

	function handleClick() {
		const totalCash =
			totalPointsSelected * vlAbateCashback > totalCashback
				? totalCashback
				: totalPointsSelected * vlAbateCashback;
		const totalResgatado = totalPointsSelected;

		onAddItemByFidelity(productsSelected);
		onAddPoints({
			totalCashback: totalCash,
			totalResgatado,
		});
	}

	return (
		<ProductContainer>
			<div className="total">
				<strong>Total de pontos {totalPoints}</strong>
				<span>Total de pontos selecionado: {totalPointsSelected}</span>
			</div>

			<Products>
				{rewards.map((item) => {
					const selected = isSelected(item);
					const disabledButton =
						totalPointsSelected + item.pontos > totalPoints && !selected;

					return (
						<Card
							key={item.cdProduto}
							data={item}
							disabled={disabledButton}
							onAdd={onAddProductsSelected}
							selected={selected}
							totalPoints={totalPoints}
						/>
					);
				})}
			</Products>

			<ButtonRedemption onClick={handleClick}>Resgatar</ButtonRedemption>
		</ProductContainer>
	);
}
