import { useState } from "react";
import { useOrderManagerContext } from "../../context";
import { CloseCash } from "./closeCash";
import { Container, Modal, Title, List, ListItem, Button } from "./style";
import { useTranslator } from "../../../../context/TranslatorContext";

export function CloseCashRegister() {
	const { getText } = useTranslator();
	const { showCloseCashRegister, setShowCloseCashRegister } = useOrderManagerContext();
	const { cashRegisterIsLoading, handleGetOrders, companies } = useOrderManagerContext();

	const [currentState, setCurrentState] = useState("list");
	const [currentCompany, setCurrentCompany] = useState(null);

	function handleClose() {
		handleGetOrders();
		setShowCloseCashRegister(false);
	}

	function handleGoBack() {
		setCurrentState("list");
		setCurrentCompany(null);
	}

	return showCloseCashRegister ? (
		<Container>
			<Modal className="scroll">
				<Title>{getText("order-manager.close-cash-register.title")}</Title>
				{currentState === "closeCash" ? (
					<CloseCash
						company={currentCompany}
						handleGoBack={handleGoBack}
						setCurrentState={setCurrentState}
					/>
				) : (
					<>
						<List>
							{companies.map((company) => (
								<ListItem key={company.cdEmpresa}>
									<div>
										<p className="bold">{company.dsEmpresa}</p>
										<p>CNPJ: {company.cnpj}</p>
									</div>
									<Button
										onClick={() => {
											setCurrentCompany(company);
											setCurrentState("closeCash");
										}}
										disabled={cashRegisterIsLoading || !company.cashRegisterOpen}
									>
										{company.cashRegisterOpen
											? getText("order-manager.close-cash-register.close-cash-register")
											: getText("order-manager.close-cash-register.cash-register-closed")}
									</Button>
								</ListItem>
							))}
						</List>
						<Button
							className="large"
							onClick={handleClose}
							disabled={cashRegisterIsLoading}
						>
							{getText("order-manager.close-cash-register.close-button")}
						</Button>
					</>
				)}
			</Modal>
		</Container>
	) : null;
}
