export const ptBR = {
	"order-manager": {
		"header-title": "Gestor de pedidos",
		"top-bar": {
			"not-delivered": "Não entregues",
			"concluded": "Concluídos",
			"scheduled-orders": "Pedidos agendados",
			"update": "Atualizar",
			"test-sound": "Testar som",
			"open-stores": "Abrir Lojas",
			"manage-stores": "Gerenciar lojas",
			"close-cash-register": "Fechar Caixa",
			"links": "Links",
			"calculate-route": "Calcular Rotas",
			"change-language": "Mudar idioma",
		},
		"chose-motoboy": {
			"title": "Escolha um motoboy",
			"cancel": "Cancelar",
			"confirm": "Confirmar",
			"select-placeholder": "Selecione um motoboy...",
		},
		"close-cash-register": {
			"title": "Fechamento de caixa",
			"close-cash-register": "Fechar Caixa",
			"cash-register-closed": "Caixa fechado",
			"close-button": "Concluir",
		},
		"scheduling-modal": {
			"title": "Pedidos Agendados",
			"empty": "Nenhum pedido agendado",
		},
		"open-cash-register": {
			"modal-title": "Gerenciamento de Caixa",
			"open-all-cash-registers": "Abrir todos os caixas",
			"close-button": "Entrar",
			"cash-register-open": "Caixa aberto",
			"open-cash-register": "Abrir caixa",
		},
		"manage-stores": {
			"store-management": "Gerenciamento de lojas",
			"manage-cash-registers": "Gerenciar caixas",
			"open-all-stores": "Abrir todas as lojas",
			"close-button": "Confirmar",
		},
		"order-card": {
			"pendent": "Pendente",
			"preparing": "Preparando",
			"delivering": "Entregando",
		},
		"link-stores": {
			"modal-title": "Links",
			"close-button": "Fechar",
		},
		"calculate-route": {
			"modal-title": "Calcular Rotas",
			"quantity-orders": "Quantidade de pedidos",
			"chose-purchases": "Escolha os pedidos",
			"generate-route": "Gerar Rota",
			"close-button": "Fechar",
		},
		"chose-language": {
			"modal-title": "Escolha seu idioma",
			"pt-br": "Português",
			"es-es": "Espanhol",
			"close-button": "Fechar",
		},
	},
	"shopping": {
		"payment-method": "",
		"finish-purchase": "Finalizar Pedido",
		"table": "Mesa",
		"withdrawal": "Retirada",
		"subtotal": "Subtotal",
		"total": "Total",
		"finish": "Finalizar",
		"add-more": "Adicionar mais itens",
		"delivery": "Delivery",
		"take-away": "",
		"delivery-to": "Entregar em",
		"chose-address": "Escolha o endereço",
		"take-on": "Retirar em",
		"using-current-location": "Usando localização atual",
		"scheduled-to": "Agendamento para",
		"free": "Grátis",
		"coupon": "Cupom",
		"cashback": "Cashback",
		"not-buying": "Você não está realizando uma compra",
		"access": "Acessar",
		"delivery-tax": "Taxa de entrega",
		"thanks-for-participating": "Obrigado por participar do sorteio",
		"draw-day": "O sorteio acontecerá no dia",
		"good-luck": "Boa sorte!",
		"placing-order": "Realizando pedido...",
		"toast": {
			"handleModifyChange": "Troco insuficiente, insira novamente",
			"handleSendOrder-error": "Você atingiu o limite de tentativas, tente novamente em 5 minutos ou selecione outra forma de pagamento!",
			"handleSendOrder-info": "Este cupom não aceita esse tipo de pagamento, por favor, selecione algum pagamento online.",
			"handleSendOrder-error-warning": "Sua sessão foi expirada",
			"handleSendOrder-error-1": "Número da mesa inválido",
			"handleSendOrder-error-2": "Comanda inválida",
			"error-1": "Localização inválida.",
			"error-2": "Localização inválida, verifique a ativação da localização do seu dispositivo e tente novamente",
			"error-3": "⚠️ Selecione um dos produtos nas regras do cupom para validar seu desconto",
			"error-4": "O valor mínimo de compra neste estabelecimento é:",
			"error-5": "O estabelecimento encontra-se fechado no momento",
			"error-6": "Empresa não atende a este endereço",
		},
	},
};
