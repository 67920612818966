import { useOrderManagerContext } from "../../context";
import { InputCheck } from "../../../../components/Forms/InputCheck";
import { Container, Modal, Title, Button, List, ListItem } from "./style";
import { SpaceLoader } from "../../../../components/loaders/spaceLoader";
import { useState } from "react";
import { ChoseAmount } from "./choseAmount";
import { useTranslator } from "../../../../context/TranslatorContext";

export function OpenCashRegister() {
	const { getText } = useTranslator();
	const { showOpenCashRegister, handleSelectCompany } = useOrderManagerContext();
	const { companiesIsLoading } = useOrderManagerContext();
	const { handleFinishCashRegisterManagement } = useOrderManagerContext();
	const { cashRegisterIsLoading, companies } = useOrderManagerContext();

	const [currentState, setCurrentState] = useState("list");
	const [currentCompany, setCurrentCompany] = useState(null);

	function handleGoBack() {
		setCurrentState("list");
		setCurrentCompany(null);
	}

	return showOpenCashRegister ? (
		<Container>
			<Modal className="scroll">
				<Title>{getText("order-manager.open-cash-register.modal-title")}</Title>
				{currentState === "choseAmount" ? (
					<ChoseAmount company={currentCompany} handleGoBack={handleGoBack} />
				) : companiesIsLoading ? (
					<SpaceLoader />
				) : (
					<>
						<Button
							className="right"
							onClick={() => setCurrentState("choseAmount")}
							disabled={
								cashRegisterIsLoading ||
								companies.some((company) => company.cashRegisterOpen)
							}
						>
							{getText("order-manager.open-cash-register.open-all-cash-registers")}
						</Button>
						<List>
							{companies.map((company) => (
								<ListItem key={company.cdEmpresa}>
									<div className="wrapper">
										<InputCheck
											checked={company.selected}
											onClick={() => {
												if (!company.cashRegisterOpen || cashRegisterIsLoading) return;
												handleSelectCompany(company.cdEmpresa);
											}}
											disabled={!company.cashRegisterOpen || cashRegisterIsLoading}
										/>
										<div>
											<p className="bold">{company.dsEmpresa}</p>
											<p>CNPJ: {company.cnpj}</p>
										</div>
									</div>
									<Button
										disabled={company.cashRegisterOpen || cashRegisterIsLoading}
										onClick={() => {
											setCurrentCompany(company);
											setCurrentState("choseAmount");
										}}
									>
										{company.cashRegisterOpen
											? getText("order-manager.open-cash-register.cash-register-open")
											: getText("order-manager.open-cash-register.open-cash-register")}
									</Button>
								</ListItem>
							))}
						</List>
						<Button
							className="large"
							disabled={cashRegisterIsLoading || companiesIsLoading}
							onClick={handleFinishCashRegisterManagement}
						>
							{getText("order-manager.open-cash-register.close-button")}
						</Button>
					</>
				)}
			</Modal>
		</Container>
	) : null;
}
